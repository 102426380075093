import ActivityRoutes from "./ActivityRoutes";
import AdmissionRoutes from "./AdmissionRoutes";
import BehaviourRoutes from "./BehaviourRoutes";
import CalendarRoutes from "./CalendarRoutes";
import CertRoutes from "./CertRoutes";
import ContactsRoutes from "./ContactsRoutes";
import DashboardRoutes from "./DashboardRoutes";
import ExamRoutes from "./ExamRoutes";
import FeeRoutes from "./FeeRoutes";
import KnowledgehubRoutes from "./KnowledgehubRoutes";
import LessonPlanRoutes from "./LessonPlanRoutes";
import MappingRoutes from "./MappingRoutes";
import MasterRoutes from "./MasterRoutes";
import MobileLandingRoutes from "./MobileLandingRoutes";
import PostsRoutes from "./PostsRoutes";
import ResourceRoutes from "./ResourceRoutes";
import ScholarAttendanceRoutes from "./ScholarAttendanceRoutes";
import ScholarExitRoutes from "./ScholarExitRoutes";
import ScholarGroupRoutes from "./ScholarGroupRoutes";
import ScholarRoutes from "./ScholarRoutes";
import SecurityRoutes from "./SecurityRoutes";
import SmsRoutes from "./SmsRoutes";
import StaffAttendanceRoutes from "./StaffAttendanceRoutes";
import StaffRoomRoutes from "./StaffRoomRoutes";
import StaffRoutes from "./StaffRoutes";
import SurveyRoutes from "./SurveyRoutes";
import TimetableRoutes from "./TimetableRoutes";
import TodoWorkRoutes from "./TodoWorkRoutes";
import TransportFeeRoutes from "./TransportFeeRoutes";
import TransportRoutes from "./TransportRoutes";
import VoiceCallRoutes from "./VoiceCallRoutes";
import WhatsappRoutes from "./WhatsappRoutes";
import HelpContentRoutes from "./HelpContentRoutes";
import PayrollRoutes from "./PayrollRoutes";
import LeaveManagementRoutes from "./LeaveManagementRoutes";
import AuditLogRoutes from "./AuditLogRoutes";
import SopRoutes from "./SopRoutes";
import CommunicationRoutes from "./CommunicationRoutes";
import StaffProfileRoutes from "./StaffProfileRoutes";
import TeacherCornerRoutes from "./TeacherCornerRoutes";
import FeedbackRoutes from "./FeedbackRoutes";
import ShowCaseRoutes from "./ShowCaseRoutes";
import QuotesRoutes from "./QuotesRoutes";
import PostAttActRoutes from "./PostAttActRoutes";
import StudentAttendanceRoutes from "./StudentAttendanceRoutes";
import LibraryRoutes from "./LibraryRoutes";

const ComponentRoutes = [
  ActivityRoutes,
  AdmissionRoutes,
  BehaviourRoutes,
  CalendarRoutes,
  CertRoutes,
  ContactsRoutes,
  DashboardRoutes,
  ExamRoutes,
  FeeRoutes,
  KnowledgehubRoutes,
  LessonPlanRoutes,
  MappingRoutes,
  MasterRoutes,
  MobileLandingRoutes,
  PostsRoutes,
  ResourceRoutes,
  ScholarAttendanceRoutes,
  ScholarExitRoutes,
  ScholarGroupRoutes,
  ScholarRoutes,
  SecurityRoutes,
  SmsRoutes,
  StaffAttendanceRoutes,
  StaffRoomRoutes,
  StaffRoutes,
  SurveyRoutes,
  TimetableRoutes,
  TodoWorkRoutes,
  TransportFeeRoutes,
  TransportRoutes,
  VoiceCallRoutes,
  WhatsappRoutes,
  HelpContentRoutes,
  PayrollRoutes,
  LeaveManagementRoutes,
  AuditLogRoutes,
  SopRoutes,
  CommunicationRoutes,
  StaffProfileRoutes,
  TeacherCornerRoutes,
  FeedbackRoutes,
  ShowCaseRoutes,
  QuotesRoutes,
  PostAttActRoutes,
  StudentAttendanceRoutes,
  LibraryRoutes
];

export default ComponentRoutes;
